<template>
  <section
    class="form-section"
    :class="{ invert: background === 'dark' || background === 'dark-with-logo' }"
    :style="backgroundStyles[background]"
  >
    <div v-if="isFirst && breadcrumbs?.length" class="wrapper wrapper--breadcrumbs">
      <CBreadcrumbs :parent-sections="breadcrumbs" :current-page-name="pageHeader" />
    </div>
    <div class="wrapper">
      <div class="form-section__content">
        <h2 v-if="title" class="form-section__title">{{ title }}</h2>
        <CTextBlock class="form-section__text" :text="text" />
      </div>
      <div class="form-section__form" v-if="form">
        <CDynamicForm
          :title="formTitle"
          :help-text="form.help_text"
          :form-key="form.key"
          :fields="form.fields"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import CTextBlock from '~/components/blocks/textBlock/CTextBlock.vue';
import CDynamicForm from '~/components/blocks/dynamicForm/CDynamicForm.vue';
import type { BlockTextFormBackground, FormModel } from '~/types/pages';
import './styles.scss';
import bgDark from '~/assets/images/bg-dark.png';
import bgLight from '~/assets/images/bg-light.png';
import bgLighten from '~/assets/images/bg-lighten.png';
import CBreadcrumbs from '~/components/breadcrumbs/CBreadcrumbs.vue';
import type { ClientBreadcrumbsItem } from '~/types/client/pages';

interface Props {
  title: string;
  text: string;
  form: FormModel;
  formTitle: string;
  background: BlockTextFormBackground;
  isFirst: boolean;
  breadcrumbs?: ClientBreadcrumbsItem[];
  pageHeader: string;
}

defineProps<Props>();

const backgroundStyles = {
  dark: {
    backgroundColor: '#00233B',
  },
  'dark-with-logo': {
    backgroundColor: '#00233B',
    backgroundImage: `url(${bgDark})`,
  },
  light: {
    backgroundColor: '#EDE5D8',
    backgroundImage: `url(${bgLight})`,
  },
  lighten: {
    backgroundColor: '#F5F5F5',
    backgroundImage: `url(${bgLighten})`,
  },
};
</script>

<style scoped></style>
